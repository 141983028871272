import { useContext, useState, useCallback, useEffect } from "react";
import { ThemeContext } from "../context/themeContext";
import { createTheme } from "@mui/material/styles";
import { LocaleContext } from "../context/context";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import api from "../api/api";
import "../styles/form.scss";

const ResetPasswordForm = (props: any): JSX.Element => {
    const context: any = useContext(LocaleContext);
    const themeContext: any = useContext(ThemeContext);
    const theme = createTheme(themeContext.theme);
    const { search } = useLocation();
    const [password, setPassword] = useState('');
    const [clientId, setClientId] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    const [searchParams] = useSearchParams();

    const onFormSubmit = (e: any) => {
        try {
            e.preventDefault();
            setSubmitting(true);
            setFormError('');
            props.setFormSuccess('');
            setClientId(searchParams.get("clientId") || "");

            api.ResetPassword({"password": password, "brandCode": theme.brand, "passwordVerification": passwordConfirm, "token": searchParams.get("token"), "clientId": searchParams.get("clientId") }, null).then(response => {
                if (response.responseCode == 200) {
                    props.setFormSuccess(context.locale[response.message]);
                } else {
                    let message = response.details.find((x:any)=> x !==undefined).message;
                    setFormError(context.locale[message]);
                } 
            })
        } catch (err: any) {
            
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <>
            <style>
                {
                `
                    input:focus{
                        outline: none;
                        border: 1.5px solid ${theme.color.primary};
                    }
                `
                }
            </style>
            {!props.formSuccess ? 
                <form onSubmit={onFormSubmit} className="form">
                     <Box className="form-container">
                        <Box className="form-box">
                            <label>{context.locale?.password}</label>
                            <input type="password" className="form-text" value={password} onInput={(e: any) => setPassword(e.target.value)} />
                        </Box>
                        <Box className="form-box">
                            <label>{context.locale?.passwordConfirm}</label>
                            <input type="password" className="form-text" value={passwordConfirm} onInput={(e: any) => setPasswordConfirm(e.target.value)} />
                        </Box>
                    </Box>
                    <Box className="form-box">
                        {Boolean(formError) &&
                            <Box className="form-error">
                                {formError}
                            </Box>
                        }
                    </Box>
                    <Box className="form-box row">
                        <Button type="submit" className="form-primary" disabled={submitting} style={{ backgroundColor: theme.color.primaryButton }}>
                            {props.isNewAccount ? context.locale?.setPassword : context.locale?.resetPassword}
                        </Button>
                    </Box>
                </form>
             : 
             <form className="form">
                <Box className="form-box">
                    <Box className="form-body">
                        {props.formSuccess}
                    </Box>
                    <Box className="form-box row">
                        <Button component={Link} className="form-primary btn" style={{ backgroundColor: theme.color.primaryButton, color: "white"}} to={`/${search}`}>{context.locale?.returnToLogin}</Button>
                    </Box>
                </Box>
            </form>
            }
        </>
    );
};

export default ResetPasswordForm;