const baseURL = process.env.REACT_APP_API_URL;

/**
 * Send POST request to API
 * @param {string} controller Controller route for API call
 * @param {string} action Action route for API call
 * @param {string} body Body of the Post call
 * @param {any} searchParams Additional Routing
 * @returns {any} Returns any object depending on the call | Returns the error object 
 */
export const FetchPost = async (controller: string, action: string, body: string, searchParams?: any, token?: any) => {
	try {
		let url = new URL(`${baseURL}${controller}/${action}`);
	
		if (typeof searchParams !== "undefined") {
			url.search = new URLSearchParams(searchParams).toString();
		}
	
		const response = await fetch(url.toString(), {
			credentials: "include",
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"X-XSRF-TOKEN": token
			},
			body: body
		});

		return await HandleResponse(response);
	} catch (error) {
		return error;
	}
};

/**
 * Send GET request to API
 * @param {string} controller Controller route for API call
 * @param {string} action Action route for API call
 * @param {string} searchParams Additional Routing
 * @returns {any} Returns any object depending on the call | Returns the error object 
 */
export const FetchGet = async (controller: string, action: string, searchParams?: any) => {
	try {
		let url = new URL(`${baseURL}${controller}/${action}`);
	
		if (typeof searchParams !== "undefined") {
			url.search = new URLSearchParams(searchParams).toString();
		}
	
		const response = await fetch(url.toString(), {
			credentials: "include",
			method: "GET",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			}
		});

		return await HandleResponse(response);
	} catch (error) {
		return error;
	}
};

/**
 * Send Delete request to API
 * @param {string} controller Controller route for API call
 * @param {string} action Action route for API call
 * @param {string} searchParams Additional Routing
 * @returns {any} Returns any object depending on the call | Returns the error object 
 */
export const FetchDelete = async (controller: string, action: string, searchParams?: any) => {
	let url = new URL(`${baseURL}${controller}/${action}`);

	if (typeof searchParams !== "undefined") {
		url.search = new URLSearchParams(searchParams).toString();
	}

	try {
		const response = await fetch(url.toString(), {
			credentials: "include",
			method: "DELETE",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			}
		});

		return await HandleResponse(response);
	} catch (error) {
		return error;
	}
};

const HandleResponse = async (response: Response) => {
	if (response.status === 401) {
		const redirectUrlHeader = response.headers.get("redirectUrl");

		if (redirectUrlHeader) {
			const separator = redirectUrlHeader.includes("?") ? "&" : "?";
			window.location.href = `${redirectUrlHeader}${separator}state=Expired`;
			return;
		}

		return await response.json();
	}

	return await response.json();
};

