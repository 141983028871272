import ReactDOM from 'react-dom/client';
import App from "./App";
import Theme from "./theme/theme.json";
import { ThemeProvider, createTheme } from "@mui/material";
import "./styles/style.scss";

const theme: any = createTheme({}, Theme);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<ThemeProvider theme={theme}>
		<App />
	</ThemeProvider>
);
