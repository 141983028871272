import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/themeContext";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { LocaleContext } from "../context/context";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import Box from '@mui/material/Box';
import { Banner } from "../components/controls/banner";
import "../styles/paper.scss";
import { NavbarBreadCrumb } from "../components/controls/navbarBreadCrumb";

interface LoginProps {
    title: string;
}
const Login = ({ title }: LoginProps) => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const context: any = useContext(LocaleContext);

	const PageComponent = (): JSX.Element => {
		return (
			<div style={{ backgroundColor: theme.color.pageBackground }}>
				<Header />
				<NavbarBreadCrumb breadcrumbText={title}/>
				<Box className="paper">
					<Box className="paper-title">
						{title ? `${getAppTerm(title, context)} ${context.locale?.loginTitle}` : context.locale?.loginTitle}
					</Box >
					<Banner/>
					<Box className="paper-body">
						<LoginForm />
					</Box>
				</Box >
				<Footer />
			</div>
		);
	}

	

	const ThemeComponent = styled(PageComponent)(({ theme }) => {
		let color = theme.color.lightGrey;

		return {
			background: color
		};
	});

	return (
		<ThemeProvider theme={theme}>
			<ThemeComponent />
		</ThemeProvider>
	);
};

export const getAppTerm = (appName : string, context: any) => {
	let editAppName  = process.env.REACT_APP_PRECEDING_TEXT + appName;
	editAppName = editAppName.replace(/\s+/g, '');
	let issuerTerm = (editAppName).charAt(0).toLowerCase() + editAppName.slice(1);

	//Get term from locale, if does not exist return issuer name
	return context.locale[issuerTerm];
}


export default Login;