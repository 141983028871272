import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/themeContext";
import { createTheme } from "@mui/material/styles";
import { LocaleContext } from "../context/context";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import api from "../api/api";
import "../styles/form.scss";
import { handleRecaptchaV3, loadFallbackRecaptcha } from "../scripts/greRecaptcha";
import {getCookies} from '../scripts/cookie';

const LoginForm = (props: any): JSX.Element => {
    const context: any = useContext(LocaleContext);
    const themeContext: any = useContext(ThemeContext);
    const theme = createTheme(themeContext.theme);
    const { search } = useLocation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitting, setSubmitting] = useState(false)
    const [formError, setFormError] = useState('')
    const [searchParams] = useSearchParams();

    const onFormSubmit = (token: any) => {
        try {
            if(isParameterValid()){
                const reCaptchaSettingsV2 = sessionStorage["recaptchaEnabledV2"];
                api.GetToken(null).then(response => {
                    setSubmitting(true);
                    setFormError('');
                    if(response && response.ok){
                        api.Login({ 
                            "username": username, 
                            "password": password, 
                            "clientID": searchParams.get("clientId"), 
                            "redirectUrl": searchParams.get("redirectUrl"), 
                            "recaptchaToken": token
                        }, null, getCookies("XSRF-TOKEN")).then(response => {
                            if (response.responseCode == 200) {
                            window.location.replace(response.message);
                            }
                            else if (response.responseCode === 205) {
                                const basePath = window.location.pathname.endsWith('/')
                                    ? window.location.pathname.slice(0, -1)
                                    : window.location.pathname;
                                const baseUrl = `${window.location.origin}${basePath}`;
                                const clientId = searchParams.get("clientId");
                                
                                const redirectUrl = `${baseUrl}/resetpassword?clientId=${clientId}&isNewAccount=${true}`;
                                window.location.replace(redirectUrl);
                            }
                            else {
                                //Check Recaptcha results
                                if (typeof(response.success) !== "undefined"){
                                    //Check if v3 Failed then do fall back
                                    if (response.version === "v3" && reCaptchaSettingsV2){
                                        LoadLoginFallbackRecaptcha();
                                    } else {
                                        //Failed reCaptcha test
                                        setFormError(context.locale?.internalServerError);
                                    }
                                } else {
                                    //Checks if error details are null or undefined
                                    if (response.details){
                                        let message = response.details.find((x:any)=> x !==undefined).message;
                                        setFormError(context.locale[message]);
                                    } else {
                                        //Returns generic Error
                                        setFormError(context.locale?.internalServerError);
                                    }
                                    //Due to CSRF, set submitting to false
                                    setSubmitting(false)
                                }
                            } 
                        })
                    }
                })
            }
        } catch (err: any) {
            setFormError(context.locale.internalServerError);
        } finally {  
            //Avoids double set submitting if false already 
            if(submitting){
                setSubmitting(false);
            }
            
        }
    }

    const isParameterValid = () => {
        if(!searchParams.get("clientId")){
            setFormError(context.locale.invalidClientId);
            return false;
        }

        return true;
    }



    const onRecaptchaSubmit = (e: any) => {
        e.preventDefault();

        if (sessionStorage["recaptchaEnabledV3"] === "true"){
            //Do reCaptcha v3 Test
            handleRecaptchaV3(onFormSubmit);
        } else if (sessionStorage["recaptchaEnabledV2"] === "true"){
            //Do reCaptcha v2 Test
            LoadLoginFallbackRecaptcha();
        } else {
            //Do no reCaptcha Test
            onFormSubmit(null);
        }
    }

    const LoadLoginFallbackRecaptcha = () => {
        const FallbackRecaptchaCallback = async (token: string) => {
            onFormSubmit({"token": token, "version": "v2"})
        }

        loadFallbackRecaptcha(
            'boxLogin',
            FallbackRecaptchaCallback
        )
    }

    const formatError = (error : string) => {
        //Get messages in string
        let insideTerms = error.match(/\{(.+?)\}/g)?.map((x) => { return (x.replace(/[{}]/g, "")) });;
        let count = -1;

        //Split the terms
        let terms = error.replace(/\{(.+?)\}/g, "|").split("|");

        //Create components to create the message
        let message = []
        for (var i = 0; i < terms.length; i++) {
            message.push(<span key={i}>{terms[i]}</span>);
            if(insideTerms){
                let link = "/";

                count++
                if(insideTerms[count] === "nearestBranch"){
                    link = theme.contactUsUrl;
                }else if (insideTerms[count] === "clickHere"){
                    link =`/ForgotPassword${search}`;
                }
                if(insideTerms[count]){
                    message.push(<Link to={link} style={{textDecorationColor: theme.color.primaryButton}} key={i + "-link"}>{context.locale[insideTerms[count]]}</Link>);
                }
            }
        }
        return message;
    }

    return (
        <>
            <style>
                {
                `
                    input:focus{
                        outline: none;
                        border: 1.5px solid ${theme.color.primary};
                    }
                `
                }
            </style>
            {/* <button className="returnButton" onClick={returnRedirect} style={{ color: theme.color.primary }}><span><i className="fa fa-angle-left" aria-hidden="true">{"<"}</i><span>{context.locale?.goBack}</span></span></button> */}
            <form onSubmit={onRecaptchaSubmit} className="form">
                <Box className="form-container">
                    <Box className="form-box">
                        <label>{context.locale?.username}</label>
                        <input id="txtUsername" type="textbox" className="form-text" value={username} onInput={(e: any) => setUsername(e.target.value)} />
                    </Box>
                    <Box className="form-box">
                        <label>{context.locale?.password}</label>
                        <input id="txtPassword" type="password" autoComplete="new-password" className="form-text" value={password} onInput={(e: any) => setPassword(e.target.value)} />
                    </Box>
                </Box>
                <Box className="form-box" id="boxLogin">
                    {Boolean(formError) &&
                        <Box className="form-error">
                            {formatError(formError)}
                        </Box>
                    }
                </Box>
                <Box className="form-box row">
                    <Button type="submit" className="form-primary" disabled={submitting} style={{ backgroundColor: theme.color.primaryButton}}>{context.locale?.login}</Button>
                </Box>
                <Box className="form-box">
                    <Link to={`/ForgotPassword${search}`} className="form-link" style={{textDecorationColor: theme.color.primaryButton}}>{context.locale?.forgotPassword}</Link>
                </Box>

            </form>
        </>
    );
};

export default LoginForm;