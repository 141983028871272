import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/themeContext";
import Navbar from "../components/Navbar";
import { createTheme } from "@mui/material/styles";

import "../styles/navbar.scss";

const Header = (): JSX.Element => {
	const themeContext: any = useContext(ThemeContext);
	const theme = createTheme(themeContext.theme);
	const [shrinkClass, setStickyClass] = useState("relative");

	useEffect(() => {
		window.addEventListener("scroll", stickNavbar);

		return () => {
			window.removeEventListener("scroll", stickNavbar);
		};
	}, []);

	const stickNavbar = () => {
		if (window !== undefined) {
			let windowHeight = window.scrollY;
			windowHeight > 0 ? setStickyClass("shrink") : setStickyClass("relative");
		}
	};
	
	return (
		<div className={"header"}>
			<div className={"header-top " + shrinkClass} style={{ backgroundColor: theme.color.primary }} />
			<Navbar className={shrinkClass} />

		</div>
	);
};

export default Header;