import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./components/AppRoutes";

function App() {
  return (
    <Router basename={process.env.REACT_APP_API_URL_DIRECTORY}>
      <Routes/>
    </Router>
  );
}

export default App;
