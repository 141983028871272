import { useEffect } from "react";

type Props = {
	id?: string;
	src: string;
	callback?: (e: Event) => void;
	noDefer?: boolean;
	noAsync?: boolean;
};

export const loadScriptTag = (props: Props) => {
	const script = document.createElement("script");

	props.id && (script.id = props.id);
	script.src = props.src;
	script.async = !props.noAsync;
	script.defer = !props.noDefer;

	document.head.appendChild(script);
	props.callback && script.addEventListener("load", props.callback, false);

	return () => {
		props.callback && script.removeEventListener("load", props.callback);
		document.head.removeChild(script);
	};
};

const useScript = (props: Props) =>
	useEffect(() => loadScriptTag(props), [props.src]);

export default useScript;
