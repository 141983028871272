import { FetchPost, FetchGet } from "./httpRequest";

const api = {
	/****************
    * TOKEN
    ****************/
	/**
	 * Login user
	 * @param {any} body Object that contains the necessary information 
	 * @param {any} search Object that contains the lat and lng of the address
	 */
	GetToken: async (search: any) => {
		let url = new URL(`${process.env.REACT_APP_API_URL}authentication/getToken`);

		return await fetch(url.toString(), {
			credentials: "include",
			method: "GET",
			headers: {
				"Accept": "application/Json",
				"Content-Type": "application/Json"
			}
		})
	},
	/****************
    * TERMS
    ****************/
	/**
	* Get Sal.Lion Terms
	* @param {any} search Contains Context and Locale
	* @returns {any} Object of all terms
	*/
	getTerms: async (search: any) => {
		return await FetchGet("term", "GetTerms", search); 
	},
	/****************
    * LOGIN
    ****************/
	/**
	 * Login user
	 * @param {any} body Object that contains the necessary information 
	 * @param {any} search Object that contains the lat and lng of the address
	 */
	Login: async (body: any, search: any, token:any) => {
		return await FetchPost("authentication", "login", JSON.stringify(body), search, token);
	},
	/**
	 * Sends reset password email
	 * @param {any} body Object that contains the necessary information to reset the password
	 * @param {any} search 
	 */
	ForgotPassword: async (body: any, search: any) => {
		return await FetchPost("authentication", "resetPassword", JSON.stringify(body), search);
	},
	/**
	 * Sends reset password email confirm
	 * @param {any} body Object that contains the necessary information to confirm reset password
	 * @param {any} search 
	 */
	ResetPassword: async (body: any, search: any) => {
		return await FetchPost("authentication", "resetPasswordConfirm", JSON.stringify(body), search);
	},
	/**
	 * Sends new account email 
	 * @param {any} body Object that contains the necessary information to send the new account email
	 * @param {any} search 
	 */
	NewAccount: async (body: any, search: any) => {
		return await FetchPost("authentication", "newAccount", JSON.stringify(body), search);
	},
	/**
	 * Validate if the clientd(Jwt token) is valid
	 * @param {any} body 
	 * @param {any} search 
	 */
	ValidateClientId: async (search: any) => {
		return await FetchGet("authentication", "validateClientId", search);
	},
	ReturnRedirectUrl: async (search: any) => {
		return await FetchGet("authentication", "returnRirectUrl", search);
	},
	VerifyRecaptcha: async(body: any) => {
		return await FetchPost("authentication", "verifyRecaptcha", JSON.stringify(body));
	},
	GetSettings: async() => {
		return await FetchGet("authentication", "getSettings");
	}
};

export default api;