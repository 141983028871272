import api from "../api/api";

export interface Setting {
    defaultLocale: string,
    currentLocale: string,
}

export interface SalLion {
    termStore?: any,
    setting?: Setting
}

declare global {
    interface Window {
        Lion: any;
    }
}

let termStore:any = null;

const Lion = {
	getTermStore : async () => {
		return await Lion.getTermStoreDatabase();
	},
	addTermStore : (termStoreData: any) => {
		termStore = termStoreData;
	},
	init : (setting: Setting) => {
		if (setting == null) {
			setting = setting;
		}
    
		if (!setting.defaultLocale) {
			throw "<SAL.Lion> You need to set a default locale to use Lion";
		}
        
		//Assign functions        
		window.Lion.getTermStore = Lion.getTermStore;
		window.Lion.addTermStore = Lion.addTermStore;
		window.Lion.setting = setting;
		window.Lion.context = Lion.getContext();
	},
	getContext : () => {
		let hostname = window.location.hostname;
		let contextList = process.env.REACT_APP_THEME_LIST?.split(",");

		if(hostname.toLowerCase().includes("mainfreight")) {
			return Lion.getContextFormat("Mainfreight");
		} else {
			let context = contextList?.find(context => hostname.toLowerCase().includes(context.toLowerCase().trim()));
			return context ? Lion.getContextFormat(context) : Lion.getContextFormat("Mainfreight");
		}
	},
	getContextFormat : (context:string) => {
		return context.toLowerCase().trim();
	},
	getTermStoreDatabase: async () =>{
		let locale = window.Lion.setting.currentLocale != undefined ? window.Lion.setting.currentLocale : window.Lion.setting.defaultLocale;
		let SearchFields = { locale: locale };
		let termData = await api.getTerms(SearchFields);
		if (!termData) {
			throw `<SAL.Lion> Term Store does not exist in the locale ${locale}`;
		} else {
			//Remove term group name from terms
			let termGroupToReplace = `${process.env.REACT_APP_API_TERM_GROUP}.`;
			let termGroup = `${process.env.REACT_APP_API_TERM_GROUP}`;
			let formatedTerms:any = new Object();

			for(let propertyName in termData[termGroup]){
				if(propertyName.startsWith(termGroupToReplace)){
					let replaced_key = propertyName.replace(termGroupToReplace, '');
					formatedTerms[replaced_key] = termData[termGroup][propertyName];
				}
			}

			return formatedTerms;
		}
	}
};

window.Lion = window.Lion || {};
window.Lion.init = Lion.init;
window.Lion.setting = null;
window.Lion.getTermStore = null;
window.Lion.addTermStore = null;

export default Lion;