export interface DeliveryData {
    region: string,
    id: string
}

export interface ThemeSetting {
    defaultTheme: string,
    currentTheme: string,
}

export interface SalTheme {
    termStore?: any,
    setting?: ThemeSetting,
    data?: DeliveryData
}

declare global {    
    interface Window {
        Theme: any;
    }
}

let themeStore:any = {};

const Theme = {
	getThemeStore : () => {
		let theme = window.Theme.setting.currentTheme !== undefined ? window.Theme.setting.currentTheme : window.Theme.setting.defaultTheme;
		if (!typeof themeStore[theme]) {
			throw `<SAL.Lion> Term Store does not exist in the locale ${theme}`;
		} else {
			return themeStore[theme];
		}
	},
	addThemeStore : (termStoreData: any) => {
		for (let theme in termStoreData) {
			if (!themeStore[theme]) {
				themeStore[theme] = termStoreData[theme];
			}
		}
	},
	init : () => {
		//Assign functions        
		window.Theme.getThemeStore = Theme.getThemeStore;
		window.Theme.addThemeStore = Theme.addThemeStore;
		window.Theme.setting = Theme.getWebsiteThemeSetting();
	},
	getWebsiteThemeSetting : () => {
		let hostname = window.location.hostname;
		let themeList = process.env.REACT_APP_THEME_LIST?.split(",");
		if(hostname.toLowerCase().includes("mainfreight")) {
			return Theme.getThemeFormat("Mainfreight");
		} else {
			let theme = themeList?.find(theme => hostname.toLowerCase().includes(theme.toLowerCase().trim()));
			return theme ? Theme.getThemeFormat(theme) : Theme.getThemeFormat("Mainfreight");
		}
	},
	getThemeFormat : (theme:string) => {
		return { defaultTheme: theme.toLowerCase().trim(), currentTheme: theme.toLowerCase().trim(), name: theme };
	}
};

window.Theme = window.Theme || {};
window.Theme.init = Theme.init;
window.Theme.setting = null;
window.Theme.getThemeStore = null;
window.Theme.addThemeStore = null;

export default Theme;