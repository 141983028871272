import { loadScriptTag } from "../hooks/useScript";
import { showSiteRecaptchaBadge } from "./constants";
import api from "../api/api";

const wndw = window as any;

export const loadRecaptchaSettings = () => {
	try {
		api.GetSettings().then(response => {
			let settings = response.message;
			//Store in session
			sessionStorage["recaptchaEnabledV3"] = settings.recaptchaEnabledV3;
			sessionStorage["recaptchaEnabledV2"] = settings.recaptchaEnabledV2;

			if (settings.recaptchaEnabledV3){
				//Load reCaptcha v3
				loadSiteRecaptcha();
			}

		})
	} catch (err: any) {
		console.log("Error getting reCaptcha results");
	} 
}


// recaptcha v3 - invisible
export const loadSiteRecaptcha = () => {
	const id = "script-site-recaptcha";
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3;

	// once added then site recaptcha does not need to be removed
	if (!siteKey || document.getElementById(id)) {
		return;
	}

	loadScriptTag({
		id: id,
		src: `https://www.google.com/recaptcha/api.js?onload=siteRecaptchaOnload&render=${siteKey}`,
	});

	wndw.siteRecaptchaOnload = () =>
		sessionStorage[showSiteRecaptchaBadge] && toggleSiteRecaptchaBadge(true);
};

export const toggleSiteRecaptchaBadge = (show: boolean) => {
    //Toggle Recaptcha badge
	show
		? (sessionStorage[showSiteRecaptchaBadge] = "1")
		: sessionStorage.removeItem(showSiteRecaptchaBadge);

	const el = document.querySelector(".grecaptcha-badge") as HTMLElement;
	el && (el.style.visibility = show ? "visible" : "hidden");
};

export const handleRecaptchaV3 = (callback: any) => {
    const grecaptcha = wndw.grecaptcha;

    //Skip Recaptcha (if not active)
	if (!grecaptcha) {
		callback();
		return;
	}

    grecaptcha.ready(function() {
        grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3, {action: 'submit'}).then(function(token : string){
            callback({"token":token, "version": "v3"})
        });
    });
}

// recaptcha v2 - "not a robot" checkbox
export const loadFallbackRecaptcha = (
	parentElementId: string,
	callbackFn: Function,
	onLoadFn?: Function
) => {

	const siteKeyV2 = process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2;

	if (!siteKeyV2) {
		return;
	}

	// script needs to be reloaded for recaptcha v2
	const id = "script-fallback-recaptcha";
	document.getElementById(id)?.remove();
	cleanupFallbackRecaptchaScripts();

	loadScriptTag({
		id: id,
		src: "https://www.google.com/recaptcha/api.js",
		callback: () => onLoadFn && onLoadFn(),
	});

	wndw.cbFallbackRecaptcha = callbackFn;
	fallbackRecaptchaAdd(parentElementId, siteKeyV2);
};


const fallbackRecaptchaAdd = (
	parentElementId: string,
	fallbackRecaptchaSiteKey: string
) => {
	toggleSiteRecaptchaBadge(false);
	fallbackRecaptchaRemove(parentElementId);

	const divString = `<div class="g-recaptcha" data-sitekey="${fallbackRecaptchaSiteKey}" data-callback="cbFallbackRecaptcha"></div>`;
	document
		.getElementById(parentElementId)
		?.insertAdjacentHTML("beforeend", divString);
};

const fallbackRecaptchaRemove = (parentElementId: string) => {
	document.querySelector(`#${parentElementId} > .g-recaptcha`)?.remove();
};

const cleanupFallbackRecaptchaScripts = () => {
	[].forEach.call(
		document.querySelectorAll("script"),
		(script: HTMLScriptElement) =>
			script.src.toLowerCase().includes("gstatic.com/recaptcha")
				? script.remove()
				: null
	);

	[].forEach.call(
		document.getElementsByTagName("iframe"),
		(ifrm: HTMLIFrameElement) =>
			ifrm.src.toLowerCase().includes("recaptcha/api2/bframe")
				? ifrm.parentElement?.remove()
				: null
	);
};