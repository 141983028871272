

export const setCookie = (cName: string, cValue: string, expDays?: number) => {
    let date = new Date();
    if(typeof(expDays) === "undefined"){
        expDays = 9999;
    }
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export const getCookies = (cName: string) => {
    return document.cookie.split(";").find((x) => x.includes(cName))?.split("=")[1];
}